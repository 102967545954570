<template>
    <div class="container-fluid d-flex justify-content-center align-items-center flex-column">
        <!-- Título del Componente -->
        <h3 class="custom-header">Gestión de Recibos</h3>

        <!-- Formulario de Selección de Año y Periodo -->
        <div class="row bg-light-recibo p-5 shadow rounded w-100">
            <!-- Año -->
            <div class="col-4 mb-3">
                <div class="form-group">
                    <label for="anio" class="form-label">Año:</label>
                    <input id="anio" type="number" class="form-control" v-model="state.anio"
                        placeholder="Ingrese el año" @change="onAnioChange" />
                </div>
            </div>

            <!-- Periodo -->
            <div class="col-4 mb-3">
                <div class="form-group">
                    <label for="periodo" class="form-label">Periodo:</label>
                    <select id="periodo" class="form-select" v-model="state.periodo">
                        <option value="" disabled>-- Seleccione un periodo --</option>
                        <option :value="Periodos.SEMANAL">Semanal</option>
                        <option :value="Periodos.QUINCENAL">Quincenal</option>
                        <option :value="Periodos.MENSUAL">Mensual</option>
                    </select>
                </div>
            </div>

            <!-- Selección de Subperiodo basado en el Periodo -->
            <div class="col-4 mb-3">
                <div v-if="state.periodo === Periodos.SEMANAL">
                    <div class="form-group">
                        <label for="semanal" class="form-label">Semanas:</label>
                        <select id="semanal" class="form-select" v-model="state.semana" @change="CargarRecibos">
                            <option value="" disabled>-- Seleccione una semana --</option>
                            <option value="">Todos</option>
                            <option v-for="s in semanas" :key="s.semana" :value="s.semana">{{ s.semana }}</option>
                        </select>
                    </div>
                </div>

                <div v-else-if="state.periodo === Periodos.QUINCENAL">
                    <div class="form-group">
                        <label for="quincenal" class="form-label">Quincenas:</label>
                        <select id="quincenal" class="form-select" v-model="state.quincena" @change="CargarRecibos">
                            <option value="" disabled>-- Seleccione una quincena --</option>
                            <option value="">Todos</option>
                            <option v-for="q in quincenas" :key="q.quincena" :value="q.quincena">{{ q.quincena }}
                            </option>
                        </select>
                    </div>
                </div>

                <div v-else-if="state.periodo === Periodos.MENSUAL">
                    <div class="form-group">
                        <label for="mes" class="form-label">Meses:</label>
                        <select id="mes" class="form-select" v-model="state.mes" @change="CargarRecibos">
                            <option value="" disabled>-- Seleccione un mes --</option>
                            <option value="">Todos</option>
                            <option v-for="mes in meses" :key="mes" :value="mes">{{ mes }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tabla de Archivos -->
        <div class="row w-100 mt-4">
            <div class="col-md-12">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Empleado</th>
                            <th>Archivo</th>
                            <th v-if="state.periodo === Periodos.SEMANAL">Semana</th>
                            <th v-else-if="state.periodo === Periodos.QUINCENAL">Quincena</th>
                            <th v-else-if="state.periodo === Periodos.MENSUAL">Mes</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody v-if="recibos.length">
                        <tr v-for="(recibo, index) in recibos" :key="index">
                            <td>{{ recibo.idAdministration }}</td>

                            <td>
                                <div v-for="(archivo, index1) in recibo.archivos" :key="index1">{{ archivo.name }}</div>
                            </td>
                            <td v-if="state.periodo === Periodos.SEMANAL">{{ recibo.semana }}</td>
                            <td v-else-if="state.periodo === Periodos.QUINCENAL">{{ recibo.quincena }}</td>
                            <td v-else-if="state.periodo === Periodos.MENSUAL">{{ recibo.mes }}</td>
                            <td>
                                <div v-for="(archivo, index1) in recibo.archivos" :key="index1">
                                    <button v-if="!archivo.aceptado" @click="aceptarRecibo(index, index1)"
                                        class="btn btn-warning btn-sm">
                                        <i class="bx bx-check"></i> Aceptar
                                    </button>

                                    <!-- Botón para descargar el archivo si está aceptado -->
                                    <button v-if="archivo.aceptado" @click="downloadFile(archivo.url)"
                                        class="btn btn-success btn-sm">
                                        <i class="bx bx-download"></i> Descargar
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="3" class="text-center">No hay archivos</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { Periodos } from "../../utils/enumeraciones";
import { RecibosService } from "@/services";
import moment from "moment-timezone";

export default {
    data: () => ({
        moment: moment,
        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dataUser: {},
        recibos: [],
        semanas: [],
        quincenas: [],
        meses: [],
        state: {
            anio: new Date().getFullYear(),
            periodo: "",
            semana: "",
            quincena: "",
            mes: "",
        },
    }),
    computed: {
        Periodos() {
            return Periodos;
        },
    },
    created() {
        this.cargarDatosIniciales(this.state.anio);
    },
    mounted() {
        this.dataUser = JSON.parse(localStorage.getItem("user") || null);
        this.getPostulaciones();
    },
    methods: {
        async cargarDatosIniciales(anio) {
            try {
                const [semanasData, quincenasData, mesesData] = await Promise.all([
                    RecibosService.getSemanasByAnio(anio),
                    RecibosService.getQuincenasByAnio(anio),
                    RecibosService.getMesesByAnio(anio),
                ]);
                this.semanas = semanasData.data.semanas;
                this.quincenas = quincenasData.data.quincenas;
                this.meses = mesesData.data.meses;
            } catch (error) {
                console.error("Error al cargar los datos:", error);
            }
        },
        onAnioChange() {
            this.cargarDatosIniciales(this.state.anio);
        },
        async getPostulaciones() {
            // this.recibos = await RecibosService.getRecibosByPostulante();
        },
        async CargarRecibos() {
            const results = await RecibosService.recibosByPeriodo(this.state);
            this.recibos = results.data.resultados;
            console.log("Recibos cargados", this.recibos);
        },
        async aceptarRecibo(index, index1) {
            // Marca el archivo como aceptado
            this.recibos[index].archivos[index1].aceptado = true;
            const idRecibo = this.recibos[index]._id;
            const idArchivo = this.recibos[index].archivos[index1]._id;

            const result = await RecibosService.aceptarRecibo({ idRecibo, idArchivo });
            console.log('result', result);
        },
        downloadFile(url) {
            // Abre el archivo en una nueva pestaña para descargar
            window.open(url, "_blank");
        },
    },
};
</script>

<style scoped land="scss">
@import "../../styles/recibos.scss";
</style>