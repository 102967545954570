export const Estado = Object.freeze({
    PENDIENTE: 'pendiente',
    PROCESANDO: 'procesando',
    COMPLETADO: 'completado',
});

export const Periodos = Object.freeze({
    SEMANAL: 'semanal',
    QUINCENAL: 'quincenal',
    MENSUAL: 'mensual',
});